import Vue from "vue";
import VueRouter, { RouterMode } from "vue-router";

import Top from "@/views/Top.vue";
import Login from "@/views/auth/Login.vue";
import Logout from "@/views/auth/Logout.vue";
import AfterLogout from "@/views/auth/AfterLogout.vue";
import JobListAll from "@/views/JobListAll.vue";
import JobSearchResult from "@/views/JobSearchResult.vue";
import JobDetail from "@/views/JobDetail.vue";
import Recommend from "@/views/Recommend.vue";
import FavoriteList from "@/views/FavoriteList.vue";

import PageNotFound from "@/views/error/PageNotFound.vue";
import NetworkError from "@/views/error/NetworkError.vue";
import AccessError from "@/views/error/AccessError.vue";
import TimeOutError from "@/views/error/TimeOutError.vue";

import BankRegist from "@/views/BankRegist.vue";

import JobCalendar from "@/views/JobCalendar.vue";

import Policy from "@/views/Policy.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";

import NoticeItem from "@/views/NoticeItem.vue";
import NoticeList from "@/views/NoticeList.vue";

import RegistrationInputEmail from "@/views/entry/registration/RegistrationInputEmail.vue";
import RegistrationInputAuthcode from "@/views/entry/registration/RegistrationInputAuthcode.vue";
import RegistrationInputPersonalInfo from "@/views/entry/registration/RegistrationInputPersonalInfo.vue";
import RegistrationConfirm from "@/views/entry/registration/RegistrationConfirm.vue";
import RegistrationThankyouPage from "@/views/entry/registration/RegistrationThankyouPage.vue";

Vue.use(VueRouter);

const routes: any = [
  {
    path: "/",
    name: "top",
    // component: () => import("@/views/Top.vue")
    component: Top,
    meta: { isPublic: true },
  },
  {
    path: "/index.html",
    name: "index",
    component: Top,
    meta: { isPublic: true },
  },

  // ログイン不要ドキュメント系

  // FAQ
  {
    path: "/faq",
    name: "faq",
    component: () => import("@/views/document/Faq.vue"),
    meta: { isPublic: true },
  },
  // 初めての方向け 使い方ガイド
  {
    path: "/user-how-to",
    name: "user-how-to",
    component: () => import("@/views/document/UserHowTo.vue"),
    meta: { isPublic: true },
  },
  // 規約表示
  {
    path: "/kiyaku-view",
    name: "kiyaku-view",
    component: () => import("@/views/document/KiyakuView.vue"),
    meta: { isPublic: true },
  },

  {
    path: "/company-service",
    name: "company-service",
    component: () => import("@/views/document/CompanyService.vue"),
    meta: { isPublic: true },
  },
  // 問い合わせトップ
  {
    path: "/inquiry",
    name: "inquiry",
    component: () => import("@/views/inquiry/InquiryIndex.vue"),
    meta: { isPublic: true },
  },
  // 問い合わせ入力画面
  {
    path: "/inquiry/input",
    name: "inquiry-input",
    component: () => import("@/views/inquiry/InquiryInput.vue"),
    meta: { isPublic: true },
  },

  // おそらく未使用(スタイルが崩れているためおそらく使われていない)

  // JOBPAYアクティベート手順
  {
    path: "/how-to-activate",
    name: "how-to-activate",
    component: () => import("@/views/document/HowToActivate.vue"),
    meta: { isPublic: true },
  },
  {
    path: "/commercial-transaction",
    name: "commercial-transaction",
    component: () => import("@/views/document/CommercialTransaction.vue"),
    meta: { isPublic: true },
  },
  {
    path: "/user-antisocial",
    name: "user-antisocial",
    component: () => import("@/views/document/UserAntisocial.vue"),
    meta: { isPublic: true },
  },

  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { isPublic: true },
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
    meta: { isPublic: true },
  },
  {
    path: "/after-logout",
    name: "after-logout",
    component: AfterLogout,
    meta: { isPublic: true },
  },

  {
    path: "/job-list-all",
    name: "job-list-all",
    component: JobListAll,
    meta: { isPublic: true },
  },
  {
    path: "/job-search-result",
    name: "job-lsearch-result",
    component: JobSearchResult,
    meta: { isPublic: true },
  },
  {
    path: "/job-detail",
    name: "job-detail",
    component: JobDetail,
    meta: { isPublic: true },
  },
  {
    path: "/job-detail?company_id=:id&job_information_id=:index&common_id=:index2",
    name: "job-detail-param",
    component: JobDetail,
    meta: { isPublic: true },
  },
  // マイページ(アプリからの参照用)
  {
    path: "/my-page",
    name: "my-page",
    component: () => import("@/views/account/MyPage.vue")
  },
  {
    path: "/recommend",
    name: "recommend",
    component: Recommend,
  },
  {
    path: "/job-calendar",
    name: "job-calendar",
    component: JobCalendar,
  },
  {
    path: "/favorite-list",
    name: "favorite-list",
    component: FavoriteList,
  },
  {
    path: "/bank-regist",
    name: "bank-regist",
    component: BankRegist,
  },

  // エラー画面
  {
    path: "*",
    name: "page-not-found",
    component: PageNotFound,
    meta: { isPublic: true },
  },
  {
    path: "/network-error",
    name: "network-error",
    component: NetworkError,
    meta: { isPublic: true },
  },
  {
    path: "/access-error",
    name: "access-error",
    component: AccessError,
    meta: { isPublic: true },
  },
  {
    path: "/timeout-error",
    name: "timeout-error",
    component: TimeOutError,
    meta: { isPublic: true },
  },

  {
    path: "/policy",
    name: "policy",
    component: Policy,
    meta: { isPublic: true },
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
    meta: { isPublic: true },
  },

  // VP規約同意画面
  {
    path: "/terms/agree",
    component: () => import("@/layouts/ProvisionalLayout.vue"),
    children: [
      // 規約同意画面
      {
        path: "",
        name: "LoginVpTermsAgree",
        component: () => import("@/views/agree/VpTermsAgree.vue")
      }
    ]
  },

  {
    path: "/terms/after-not-agree",
    name: "VpTermsAfterNotAgree",
    component: () => import("@/views/agree/VpTermsAfterNotAgree.vue"),
    meta: { isPublic: true },
  },

  {
    path: "/notice-list",
    name: "notice-list",
    component: NoticeList
  },
  {
    path: "/notice-item",
    name: "notice-item",
    component: NoticeItem
  },

  // 認証
  {
    path: "/auth",
    component: () => import("@/layouts/ProvisionalLayout.vue"),
    meta: { isPublic: true },
    children: [
      // パスワード更新用メール入力
      {
        path: "password/update/mail/input",
        name: "AuthPasswordUpdateMailInput",
        component: () => import("@/views/auth/password/AuthPasswordUpdateMailInput.vue"),
        meta: { isPublic: true },
      },
      // パスワード更新用メール送信済
      {
        path: "password/update/mail/sent",
        name: "AuthPasswordUpdateMailSent",
        component: () => import("@/views/auth/password/AuthPasswordUpdateMailSent.vue"),
        meta: { isPublic: true },
      },
      // パスワード変更画面
      {
        path: "password/update/input/:token",
        name: "AuthPasswordUpdateInputToken",
        component: () => import("@/views/auth/password/AuthPasswordUpdateInputToken.vue"),
        meta: { isPublic: true },
      },
    ],
  },

  // 新規会員登録
  {
    path: "/member",
    component: () => import("@/layouts/ProvisionalLayout.vue"),
    meta: { isPublic: true },
    children: [
      // アプリからアクセスされるため、/memberでアクセスできるようにしておく
      {
        path: "",
        name: "RegistrationIndex",
        component: () => import("@/views/entry/registration/RegistrationVpTermsAgree.vue"),
        meta: { isPublic: true },
      },
    ],
  },

  // 新規会員登録
  {
    path: "/registration",
    component: () => import("@/layouts/ProvisionalLayout.vue"),
    meta: { isPublic: true },
    children: [
      {
        path: "input-email",
        name: "RegistrationInputEmail",
        component: RegistrationInputEmail,
        meta: { isPublic: true },
      },
      {
        path: "input-authcode",
        name: "RegistrationInputAuthcode",
        component: RegistrationInputAuthcode,
        meta: { isPublic: true },
      },
      {
        path: "input-personal",
        name: "RegistrationInputPersonalInfo",
        component: RegistrationInputPersonalInfo,
        meta: { isPublic: true },
      },
      {
        path: "confirm",
        name: "RegistrationConfirm",
        component: RegistrationConfirm,
        meta: { isPublic: true },
      },
      {
        path: "thankyou-page",
        name: "RegistrationThankyouPage",
        component: RegistrationThankyouPage,
        meta: { isPublic: true },
      },
    ],
  },

  // 会員用画面
  {
    path: "/account",
    component: () => import("@/layouts/WorkerLayout.vue"),
    children: [
      // 会員情報変更
      {
        path: "personal/edit",
        component: () => import("@/layouts/ProvisionalLayout.vue"),
        children: [
          {
            path: "input",
            name: "AccountPersonalEditInput",
            component: () => import("@/views/account/personal/edit/PersonalEditInput.vue")
          },
          {
            path: "confirm",
            name: "AccountPersonalEditConfirm",
            component: () => import("@/views/account/personal/edit/PersonalEditConfirm.vue")
          },
          {
            path: "authcode",
            name: "AccountPersonalEditCheckAuthcode",
            component: () => import("@/views/account/personal/edit/PersonalEditCheckAuthcode.vue")
          },
          {
            path: "thankyou-page",
            name: "AccountPersonalEditThankyouPage",
            component: () => import("@/views/account/personal/edit/PersonalEditThankyouPage.vue")
          },
        ]
      },

      // JOBPAY連携
      {
        path: "connect/jobpay",
        component: () => import("@/layouts/ProvisionalLayout.vue"),
        children: [
          // 規約同意画面
          {
            path: "",
            name: "JobpayTermsAgree",
            component: () => import("@/views/account/connect/jobpay/JobpayTermsAgree.vue")
          },
          {
            path: "input-email",
            name: "AccountJobpayConnectInputEmail",
            component: () => import("@/views/account/connect/jobpay/InputEmail.vue")
          },
          {
            path: "input-authcode",
            name: "AccountJobpayConnectInputAuthcode",
            component: () => import("@/views/account/connect/jobpay/InputAuthcode.vue")
          },
          {
            path: "input-personal",
            name: "AccountJobpayConnectInputRegistration",
            component: () => import("@/views/account/connect/jobpay/InputRegistration.vue")
          },
          {
            path: "input-identify-photo",
            name: "AccountJobpayConnectInputIdentifyPhoto",
            component: () => import("@/views/account/connect/jobpay/InputIdentifyPhoto.vue")
          },
          {
            path: "input-identify-photo-own",
            name: "AccountJobpayConnectInputIdentifyPhotoOwn",
            component: () => import("@/views/account/connect/jobpay/InputIdentifyPhotoOwn.vue")
          },
          {
            path: "input-identify-photo-own2",
            name: "AccountJobpayConnectInputIdentifyPhotoOwn2",
            component: () => import("@/views/account/connect/jobpay/InputIdentifyPhotoOwn2.vue")
          },
          {
            path: "input-identify1",
            name: "AccountJobpayConnectInputIdentify1",
            component: () => import("@/views/account/connect/jobpay/InputIdentify1.vue")
          },
          {
            path: "input-identify-other",
            name: "AccountJobpayConnectInputIdentifyOther",
            component: () => import("@/views/account/connect/jobpay/InputIdentifyOther.vue")
          },
          {
            path: "confirm",
            name: "AccountJobpayConnectConfirm",
            component: () => import("@/views/account/connect/jobpay/Confirm.vue")
          },
          {
            path: "thankyou-page",
            name: "AccountJobpayConnectThankyouPage",
            component: () => import("@/views/account/connect/jobpay/ThankyouPage.vue")
          },
        ],
      },
      
      // 退会画面
      {
        path: "close",
        component: () => import("@/layouts/ProvisionalLayout.vue"),
        children: [
          {
            path: "input",
            name: "AccountCloseInput",
            component: () => import("@/views/account/close/CloseAccountInput.vue")
          },
          {
            path: "confirm",
            name: "AccountCloseConfirm",
            component: () => import("@/views/account/close/CloseAccountConfirm.vue")
          },
          {
            path: "complete",
            name: "AccountCloseComplete",
            component: () => import("@/views/account/close/CloseAccountComplete.vue")
          },
        ]
      },

      // マイページ
      {
        path: "my-page",
        name: "AccountMyPage",
        component: () => import("@/views/account/MyPage.vue")
      },

    ]
  },

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: function (to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ selector: to.hash, offset: { x: 0, y: 120 } })
        }, 100)
      })
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
});

// ナビゲーションガード
// isPublic でない場合(=認証が必要な場合)、かつ、ログインしていない場合
router.beforeEach((to, from, next) => {
  let tokenstr = localStorage.getItem("token");
  if (tokenstr === null) {
    tokenstr = "notlogin";
    if (to.matched.some((record) => !record.meta.isPublic)) {
      next({ path: "/login", query: { redirect: to.fullPath } });
    } else {
      next();
    }
  } else {
    if (
      to.matched.some((record) => !record.meta.isPublic) &&
      !tokenstr.startsWith("jwt ey")
    ) {
      next({ path: "/login", query: { redirect: to.fullPath } });
    } else {
      next();
    }
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  router["referrer"] = from;
  next();
});

export default router;
